import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='My Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        I take my commitment to my readers seriously. If you need my help with
        your project, have questions about how to use the site or are
        experiencing any technical difficulties, please do not hesitate to
        contact me.
      </Text>
    </Card>
  </Section>
)

export default Commitment